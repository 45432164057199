import React from 'react';

function Transfers() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill='#CC7E63'
				stroke='#CC7E63'
				d='M3.79 5.365A2 2 0 015.753 3.75h8.495a2 2 0 011.962 1.615l.665 3.385H3.125l.665-3.385zM1.25 11.75a3 3 0 013-3h11.5a3 3 0 013 3v3.05a.2.2 0 01-.2.2H1.45a.2.2 0 01-.2-.2v-3.05z'
			></path>
			<path
				fill='#CC7E63'
				d='M7.5 1.95a.7.7 0 01.7-.7h3.6a.7.7 0 01.7.7v.55h-5v-.55z'
			></path>
			<path stroke='#fff' strokeLinecap='round' d='M7.5 12.5h5'></path>
			<path stroke='#FFF8EF' strokeLinecap='round' d='M1 9h18'></path>
			<path
				fill='#CC7E63'
				stroke='#CC7E63'
				d='M16.25 16.875h1.25v.625a.625.625 0 11-1.25 0v-.625zM2.5 16.875h1.25v.625a.625.625 0 11-1.25 0v-.625z'
			></path>
			<path
				stroke='#F8F8F8'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.497'
				d='M3.748 12.5h.013M16.248 12.5h.012'
			></path>
		</svg>
	);
}

export default Transfers;
