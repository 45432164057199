import React from 'react';

function AudioGuide() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				stroke='#009AB8'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3.75 13.75v-4.5c0-1.79.658-3.507 1.83-4.773C6.754 3.211 8.343 2.5 10 2.5s3.247.711 4.42 1.977c1.171 1.266 1.83 2.983 1.83 4.773v4.5'
			></path>
			<path
				fill='#009AB8'
				stroke='#009AB8'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M2.75 16.25H5v-5H2.75c-.398 0-.78.2-1.06.558-.282.357-.44.842-.44 1.347v1.19c0 .505.158.99.44 1.347.28.357.662.558 1.06.558zM17.25 16.25H15v-5h2.25c.398 0 .78.2 1.06.558.282.357.44.842.44 1.347v1.19c0 .505-.158.99-.44 1.347-.28.357-.662.558-1.06.558z'
			></path>
			<path
				stroke='#009AB8'
				strokeLinecap='round'
				d='M7.5 14.375V12.5M12.5 14.375V12.5M10 15v-5'
			></path>
		</svg>
	);
}

export default AudioGuide;
