import React from 'react';

function HotelPickup() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				stroke='#E6A900'
				strokeLinecap='round'
				d='M1.875 18.125h16.25'
			></path>
			<path
				fill='#E6A900'
				stroke='#E6A900'
				d='M3.75 3a.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5v13.25h-7.5V3z'
			></path>
			<path
				fill='#FFF8EF'
				d='M8.75 8.75a.625.625 0 110 1.25h-2.5a.625.625 0 110-1.25h2.5zM8.75 5a.625.625 0 110 1.25h-2.5a.625.625 0 110-1.25h2.5zM6.25 13.325c0-.11.09-.2.2-.2h2.1c.11 0 .2.09.2.2v3.55h-2.5v-3.55z'
			></path>
			<path
				fill='#E6A900'
				stroke='#E6A900'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.75'
				d='M17.5 13.48c0 1.492-1.875 2.77-1.875 2.77s-1.875-1.278-1.875-2.77c0-.508.197-.996.55-1.356.35-.36.828-.562 1.325-.562s.974.203 1.326.562c.351.36.549.848.549 1.356z'
			></path>
			<path
				stroke='#F8F8F8'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.497'
				d='M15.622 13.438h.012'
			></path>
		</svg>
	);
}

export default HotelPickup;
