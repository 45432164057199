import React from 'react';

export const OperatingHoursOpen = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill='#088943'
				stroke='#088943'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3 6.5h14c.662 0 1.1.468 1.1.929v8.642c0 .461-.438.929-1.1.929H3c-.662 0-1.1-.468-1.1-.929V7.43c0-.461.438-.929 1.1-.929z'
			></path>
			<path
				fill='#F2FDEB'
				stroke='#F2FDEB'
				strokeLinejoin='round'
				strokeWidth='0.1'
				d='M7.911 13.204c0 .028.023.05.05.05h.4a.05.05 0 00.05-.05v-.93h.378c.37 0 .655-.069.85-.218.197-.151.292-.379.292-.676 0-.291-.092-.516-.286-.666-.192-.147-.475-.216-.844-.216h-.84a.05.05 0 00-.05.05v2.656zm2.581 0c0 .028.022.05.05.05h1.912a.05.05 0 00.05-.05v-.356a.05.05 0 00-.05-.05h-1.466v-.748h.922a.05.05 0 00.05-.05v-.356a.05.05 0 00-.05-.05h-.922v-.64h1.406a.05.05 0 00.05-.05v-.356a.05.05 0 00-.05-.05h-1.852a.05.05 0 00-.05.05v2.656zm4.292.028a.05.05 0 00.042.022h.388a.05.05 0 00.05-.05v-2.66a.05.05 0 00-.05-.05h-.392a.05.05 0 00-.05.05v1.818l-1.225-1.846a.05.05 0 00-.041-.022h-.464a.05.05 0 00-.05.05V13.2c0 .028.022.05.05.05h.392a.05.05 0 00.05-.05v-1.915l1.3 1.947zM4.7 11.876c0 .42.131.777.365 1.03.235.252.568.396.965.396.404 0 .743-.144.98-.396.238-.253.37-.61.37-1.03 0-.42-.131-.777-.365-1.03-.235-.252-.568-.396-.965-.396-.404 0-.743.144-.98.396-.238.252-.37.61-.37 1.03zm2.164.008c0 .307-.083.546-.225.708-.142.16-.347.25-.605.25a.757.757 0 01-.597-.253c-.14-.162-.221-.402-.221-.705v-.02c0-.305.083-.543.225-.704.142-.16.347-.25.605-.25.255 0 .458.091.597.252.14.162.221.4.221.702v.02zm1.925-.05h-.378v-.896h.39c.238 0 .392.035.487.104.09.067.135.171.135.338 0 .172-.047.28-.141.348-.098.07-.255.106-.493.106z'
			></path>
			<path
				stroke='#088943'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M7.5 5l1.732-2.078a1 1 0 011.536 0L12.5 5'
			></path>
		</svg>
	);
};

export const OperatingHoursClosed = ({
	fill = '#888888',
	stroke = '#888888',
}: {
	fill?: string;
	stroke?: string;
}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill={fill}
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3 6.5h14c.662 0 1.1.468 1.1.929v8.642c0 .461-.438.929-1.1.929H3c-.662 0-1.1-.468-1.1-.929V7.43c0-.461.438-.929 1.1-.929z'
			></path>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M7.5 5l1.732-2.078a1 1 0 011.536 0L12.5 5'
			></path>
			<path
				fill='#FFF8EF'
				stroke='#FFF8EF'
				strokeWidth='0.1'
				d='M5.596 11.415h.06l-.01-.059c-.053-.309-.182-.537-.37-.687-.188-.15-.429-.219-.698-.219-.33 0-.613.124-.814.345-.2.221-.314.535-.314.909 0 .372.113.686.31.907.198.221.477.347.8.347.278 0 .527-.07.72-.225.192-.155.322-.39.37-.71l.008-.057h-.471l-.007.041c-.034.203-.11.332-.213.411a.637.637 0 01-.4.115.594.594 0 01-.473-.211c-.115-.137-.184-.341-.184-.604v-.028c0-.261.07-.465.186-.602a.604.604 0 01.478-.212c.157 0 .288.032.387.107.1.074.175.197.212.39l.008.042h.415zm.358 1.451v.05H7.499v-.415H6.404v-2.009H5.954V12.866zm4.216-.792h-.06l.011.059c.047.26.153.468.33.61.175.14.414.211.717.211.272 0 .493-.076.648-.212a.719.719 0 00.238-.555.57.57 0 00-.266-.509c-.155-.101-.357-.15-.548-.188h0l-.03-.005a2.03 2.03 0 01-.402-.102.325.325 0 01-.123-.084.18.18 0 01-.041-.12c0-.098.038-.176.106-.23a.487.487 0 01.31-.091c.153 0 .268.031.35.096.08.065.137.169.16.33l.006.043h.475l-.008-.057c-.036-.27-.143-.477-.314-.616-.17-.138-.397-.204-.666-.204-.496 0-.866.31-.866.747 0 .243.113.401.276.506.16.103.366.155.56.194h0c.17.031.316.062.42.111a.31.31 0 01.114.083c.024.03.037.067.037.114 0 .108-.04.191-.111.248a.533.533 0 01-.336.094c-.173 0-.295-.032-.381-.098-.086-.065-.145-.17-.179-.335l-.008-.04h-.419zm2.216.792v.05H14.159v-.411h-1.327v-.643H13.684v-.411H12.833v-.548H14.105v-.411H12.386V12.866zm2.099 0v.05H15.305c.396 0 .71-.103.926-.31.215-.206.324-.51.324-.895 0-.392-.105-.699-.314-.907-.21-.209-.515-.312-.902-.312H14.484V12.866zm-6.92-1.162c0 .37.115.683.321.905.207.223.5.349.849.349.355 0 .653-.126.862-.349.21-.222.325-.536.325-.905 0-.37-.115-.683-.32-.905a1.127 1.127 0 00-.85-.349c-.355 0-.653.126-.862.349-.209.222-.325.536-.325.905zm1.893.007c0 .268-.072.475-.195.615a.672.672 0 01-.525.217.656.656 0 01-.517-.219c-.122-.14-.192-.348-.192-.613v-.018c0-.266.072-.472.195-.612a.673.673 0 01.525-.216c.222 0 .397.079.518.219s.191.347.191.61v.017zm5.857.797h-.38V10.9h.404c.264 0 .448.065.567.19.119.126.181.323.181.607v.021c0 .274-.066.47-.19.596-.125.127-.315.194-.582.194z'
			></path>
		</svg>
	);
};

export const PeachClock = () => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z'
			fill='#CC7E63'
			stroke='#CC7E63'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M9.375 5V10L14.375 12.5'
			stroke='#FFF8EF'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);
