import React from 'react';

function InstantConfirmation() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<circle cx='10' cy='10' r='8.75' fill='#0F43BD'></circle>
			<path
				stroke='#EFF4FF'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M14.097 7.5L8.75 13.125l-2.5-2.5'
			></path>
		</svg>
	);
}

export default InstantConfirmation;
