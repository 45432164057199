import React from 'react';

function ExtendedValidity() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill='#0F43BD'
				d='M2 2.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v11.293a.5.5 0 01-.146.353L16 16l-1.854 1.854a.5.5 0 01-.353.146H2.5a.5.5 0 01-.5-.5v-15z'
			></path>
			<path fill='#4377F0' d='M18 14l-4 4v-3.5a.5.5 0 01.5-.5H18z'></path>
			<path
				stroke='#EFF4FF'
				strokeLinecap='round'
				strokeWidth='0.75'
				d='M3.5 5h13'
			></path>
			<path
				stroke='#fff'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M13.125 8.75L8.75 12.969l-1.875-1.875'
			></path>
		</svg>
	);
}

export default ExtendedValidity;
