/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const LongDescriptorsWrapper = styled.div`
	.combo-popup-content .core-popup-slide-body {
		height: 33.75rem;
	}
`;

export const DescriptorsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 0.75rem 0;

	@media (min-width: 768px) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1.5rem;
		margin: 1.5rem 0;
	}
`;

export const Descriptor = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;

	@media (max-width: 767px) {
		&.is-combo {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-areas:
				'icon info'
				'icon action';

			.descriptor {
				&-icon {
					grid-area: icon;
				}

				&-info {
					grid-area: info;

					&.no-subtext {
						margin-top: 0.625rem;
					}
				}

				&-action {
					grid-area: action;
					margin-top: -0.25rem;
					margin-left: 0;
				}
			}
		}
	}

	@media (min-width: 768px) {
		flex: 0 0 calc(50% - 1.5rem);

		&:has(button) > button {
			margin: 0.75rem 1.5rem 0 auto;
		}

		&.is-combo {
			.descriptor-info {
				margin-top: 0.625rem;
			}
		}
	}
`;

export const IconWrapper = styled.div<{ $backgroundColor: string }>`
	background: ${({ $backgroundColor }) => $backgroundColor};
	border-radius: 0.375rem;
	width: 2.25rem;
	height: 2.25rem;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.125rem;

	svg {
		height: 1.25rem;
		width: 1.25rem;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	&.bold-subtext {
		.descriptor {
			&-label {
				${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
			}

			&-text {
				${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
			}
		}
	}

	&.no-subtext {
		align-self: center;
	}

	.descriptor-text {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
		color: ${colors.GREY_DS['G3']};
	}

	@media (min-width: 768px) {
		&.no-subtext {
			align-self: flex-start;
			margin-top: 0.5rem;
		}

		h3 {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM_HEAVY)};
		}

		&.reversed-column {
			flex-direction: column-reverse;
			p {
				${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_SMALL)};
				color: ${colors.GREY_DS['G3']};
			}
		}

		&.bold-subtext {
			.descriptor {
				&-label {
					${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)}
				}

				&-text {
					${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM_HEAVY)};
					color: ${colors.GREY_DS['G2']};
				}
			}
		}
	}
`;

export const DescriptorTitle = styled.h3`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)};
`;

export const DescriptorActionTitle = styled.button<{ $labelColor?: string }>`
	display: flex;
	align-items: center;
	gap: 0.125rem;
	margin-bottom: -0.125rem;

	.descriptor {
		&-label {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
			${({ $labelColor }) => $labelColor && `color: ${$labelColor};`}
		}

		&-icon {
			margin-top: 0.125rem;
		}
	}

	@media only screen and (min-width: 768px) {
		.descriptor-label {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM_HEAVY)};
		}
	}
`;

export const DescriptorAction = styled.button`
	flex-shrink: 0;
	display: flex;
	gap: 0.25rem;
	align-items: center;
	justify-content: space-between;
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
	color: ${colors.PURPS};
	margin-left: auto;
	align-self: center;

	svg {
		margin-top: 0.125rem;
		path {
			stroke: ${colors.PURPS};
		}
	}

	@media (min-width: 768px) {
		margin-left: 0;
		align-self: start;
	}
`;

export const ModalWrapper = styled.div`
	.core-modal-container {
		background: rgba(0, 0, 0, 0.4);
		.core-modal-content {
			min-width: auto;
			min-height: auto;
			border-radius: 0.5rem;
			.operating-hours-modal {
				.modal-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.75rem 1.5rem;
					border-bottom: 0.0625rem solid ${colors.GREY_DS.G6};
					${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
					color: ${colors.GREY_DS.G2};
				}

				.modal-tabs {
					display: flex;
					gap: 1.5rem;
					border-bottom: 0.0625rem solid ${colors.GREY_DS.G6};
					padding: 0 1.5rem;

					.modal-tab-item {
						padding-bottom: 0.5rem;
						border-bottom: 0.0625rem solid transparent;
						margin-bottom: -0.0625rem;
						color: ${colors.GREY_DS.G3};
						${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)}
						transition: border-bottom ease-in-out 0.3s;

						&.selected {
							color: ${colors.GREY['22']};
							border-bottom: 0.0625rem solid ${colors.GREY['22']};
						}
					}
				}

				.oh-dweb-tbody {
					padding: 1rem 1.5rem 1.5rem;
				}

				&.is-combo {
					.modal-header {
						padding: 0.75rem 1.5rem 1rem 1.5rem;
						border-bottom: none;
					}

					.oh-dweb-tbody {
						padding: 1.5rem;
					}
				}
			}
		}
	}
`;

// Combo Table Styles
export const ComboMwebDrawerContainer = styled.div`
	.oh-mweb-tbody {
		padding: 1.5rem 1.5rem 2rem;

		.oh-mweb-tr {
			gap: 3rem;
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}
			&.active {
				${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
			}
			td {
				&:last-child {
					.last-entry-time {
						margin-top: 0.125rem;
						${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)}
					}
				}
			}
		}
	}

	.drawer-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 1.5rem 0 1rem;
		color: ${colors.GREY_DS.G2};
		.oh-name {
			color: ${colors.GREY['22']};
			${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
		}
		.close-btn {
			padding: 0.3125rem 0 0.3125rem 0.3125rem;
		}
	}

	.drawer-tabs {
		display: flex;
		align-items: center;
		padding: 1rem 1.5rem 0 1rem;
		border-bottom: 1px solid ${colors.GREY.E2};
		gap: 1rem;
	}

	.drawer-tab-item {
		color: ${colors.GREY_DS.G3};
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_REGULAR)};
		padding-bottom: 0.5rem;
		border-bottom: 0.0625rem solid transparent;
		margin-bottom: -0.0625rem;
		transition: border-bottom ease-in-out 0.3s;

		&.selected {
			color: ${colors.GREY_DS.G2};
			border-bottom: 1px solid ${colors.GREY_DS.G2};
		}
	}
`;

export const SingleMwebDrawerContainer = styled.div`
	.oh-mweb-tbody {
		padding: 1.5rem 1.5rem 2rem;

		.oh-mweb-tr {
			gap: 3rem;
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}
			&.active {
				${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
			}
			td {
				&:last-child {
					.last-entry-time {
						margin-top: 0.125rem;
						${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)}
					}
				}
			}
		}
	}

	.drawer-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 1.5rem;
		border-bottom: 1px solid ${colors.GREY.E2};
		color: ${colors.GREY_DS.G2};
		.poi-name {
			color: ${colors.GREY_DS.G3};
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		}
		.oh-name {
			color: ${colors.GREY_DS.G2};
			${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
		}
		.close-btn {
			padding: 0.3125rem 0 0.3125rem 0.3125rem;
		}
	}
`;
