import React from 'react';

function Duration() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			fill='none'
			viewBox='0 0 18 18'
		>
			<path
				fill='#CC7E63'
				stroke='#CC7E63'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M9 17.333A8.333 8.333 0 109 .667a8.333 8.333 0 000 16.666z'
			></path>
			<path
				stroke='#FFF8EF'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M8.375 4v5l5 2.5'
			></path>
		</svg>
	);
}

export default Duration;
