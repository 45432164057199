import React from 'react';

function GuidedTour() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill='#009AB8'
				d='M11.786 18.125v-1.16a2.321 2.321 0 00-2.322-2.322H4.821A2.321 2.321 0 002.5 16.964v1.161'
			></path>
			<path
				stroke='#009AB8'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.929'
				d='M11.786 18.125v-1.16a2.321 2.321 0 00-2.322-2.322H4.821A2.321 2.321 0 002.5 16.964v1.161h9.286z'
			></path>
			<path
				fill='#009AB8'
				stroke='#009AB8'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.929'
				d='M7.143 12.321a2.321 2.321 0 100-4.642 2.321 2.321 0 000 4.642z'
			></path>
			<path
				fill='#009AB8'
				d='M11.786 1.968v5.13h6.339l-1.83-2.047a.093.093 0 01-.01-.11l1.84-3.066h-6.246a.093.093 0 00-.093.093z'
			></path>
			<path
				stroke='#009AB8'
				strokeLinecap='round'
				strokeWidth='0.929'
				d='M11.786 12.321V7.098m0 0v-5.13c0-.051.042-.093.093-.093h6.246l-1.84 3.066a.093.093 0 00.01.11l1.83 2.047h-6.34z'
			></path>
		</svg>
	);
}

export default GuidedTour;
