import { useEffect } from 'react';

export const useBodyScrollLock = (lockScroll: boolean = false) => {
	const toggleBodyOverflow = (disableOverflow: boolean) => {
		if (typeof window !== 'undefined' && window.document) {
			if (disableOverflow) {
				document.body.style.touchAction = 'none';
				window?.document?.body?.classList?.add('overflow-hidden');
			} else {
				document.body.style.touchAction = '';
				window?.document?.body?.classList?.remove('overflow-hidden');
			}
		}
	};

	useEffect(() => {
		toggleBodyOverflow(lockScroll);

		return () => toggleBodyOverflow(false);
	}, [lockScroll]);
};
