import React from 'react';

function MealsIncluded() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				stroke='#E6A900'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.25'
				d='M7.28 7.5S5.405 6.875 6.715 5c0 0 1.13-1.25-.06-2.5M10.405 7.5S8.53 6.875 9.84 5c0 0 1.13-1.25-.06-2.5M13.53 7.5s-1.875-.625-.566-2.5c0 0 1.131-1.25-.059-2.5'
			></path>
			<path
				fill='#E6A900'
				stroke='#E6A900'
				strokeWidth='1.25'
				d='M18.116 9.375c-.053 1.826-.365 3.587-1.352 4.934-1.069 1.459-3.034 2.566-6.764 2.566-3.616 0-5.588-1.103-6.687-2.571-1.011-1.35-1.365-3.114-1.428-4.929h16.231z'
			></path>
		</svg>
	);
}

export default MealsIncluded;
