'use client';

import type { MutableRefObject } from 'react';
import React, { useRef, useState } from 'react';

import { css, cva, cx } from '@headout/pixie/css';

import Conditional from 'Components/common/conditional';

import { CancelSvg } from 'Assets/svg/product';

import { useBodyScrollLock } from 'Hooks/useBodyScrollLock';

const corePopupSlide = cva({
	base: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 999,
		visibility: 'hidden',
		transition: 'visibility 0.2s',

		'&.core-popup-show': {
			visibility: 'visible',

			'& .core-popup-background, .core-popup-slide-header': {
				opacity: 1,
			},

			'& .core-popup-slide-body': {
				transform: 'translate3d(0, 0, 0)',
			},
		},

		'& .core-popup-slide-body': {
			position: 'fixed',
			bottom: 0,
			left: 0,
			background: 'semantic.surface.light.white',
			width: '100%',
			transform: 'translate3d(0, 100%, 0)',
			transition: '200ms linear',
			borderRadius: '1.25rem 1.25rem 0 0',

			'& .selector-modal': {
				height: '100%',
				padding: '0.9375rem 0 0',

				'& .selection-list-item': {
					padding: '1rem 0',
					borderBottom: '1px solid',
					borderColor: 'semantic.dividers.dark',

					'& span': {
						textStyle: 'Semantics/UI Label/Medium',
					},

					'& .selected-check': {
						width: '1.25rem',
						height: '1.25rem',
					},
				},
			},

			'& .selection-list': {
				height: 'calc(100% - 5.625rem)',
				overflow: 'auto',
				padding: '0 1.5rem',
			},

			'& .modal-header': {
				width: '100vw',
			},

			'& .search-bar': {
				height: '3.25rem',
				width: 'calc(100vw - 3rem)',
				borderRadius: '0.5rem',
				border: '1px solid',
				borderColor: 'semantic.dividers.dark',
				background: 'semantic.surface.light.white',
				margin: '0 auto',

				'& .search-icon': {
					margin: '0 0.625rem 0 1.125rem',
					width: '0.75rem',
					height: '0.75rem',
					fill: 'semantic.text.grey.2',
				},

				'& input': {
					background: 'semantic.surface.light.white',
					color: 'semantic.text.grey.2',
					textStyle: 'Semantics/UI Label/Medium',
				},
			},

			'& .selector-modal .close-icon': {
				display: 'none',
			},
		},
	},
	variants: {
		fullHeight: {
			true: {
				'& .core-popup-slide-body': {
					top: '4.0625rem',
					height: 'calc(100% - 4.0625rem)',
				},
			},
			false: {
				'& .core-popup-slide-body': {
					top: '',
					height: 'max-content',
				},
			},
		},
		allowTouchEvents: {
			true: {
				'& .core-popup-slide-body, .core-popup-background': {
					touchAction: 'none',
				},
			},
		},
	},
});

const corePoppupSlideHeader = css({
	position: 'absolute',
	left: '0',
	right: '0',
	padding: '1.25rem 1.25rem 0',
	opacity: 0,
	transition: '200ms linear',

	'& .core-popup-slide-heading': {
		color: 'semantic.text.light.white',
		fontSize: '1.25rem',
		lineHeight: '1.5625rem',
	},

	'& svg': {
		height: '1.5625rem',
		width: '1.5625rem',
		float: 'right',
		fill: 'semantic.text.light.white',
	},
});

const corePopupBackground = css({
	position: 'absolute',
	top: '0',
	left: '0',
	right: '0',
	bottom: '0',
	opacity: 0,
	backgroundColor: 'rgba(0, 0, 0, 0.7)',
	transition: '200ms linear',
});

const topNotch = css({
	height: '0.25rem',
	width: '2.125rem',
	position: 'absolute',
	left: '50%',
	top: '0.375rem',
	borderRadius: '0.75rem',
	background: 'core.grey.300',
	transform: 'translateX(-50%)',
});

const topDragger = css({
	position: 'absolute',
	top: '0',
	left: '0',
	right: '0',
	height: '1.25rem',
});

type Props = {
	heading?: string;
	visible?: boolean;
	onClose?: (...args: any[]) => any;
	isCategoriesModal?: boolean;
	isFiltersModal?: boolean;
	isSelectorModal?: boolean;
	ContainerClassName?: string;
	children?: React.ReactNode;
	scrollContainerId?: string;
	allowTouchEvents?: boolean;
	zIndex?: number;
	fullHeight?: boolean;
	makeBackButtonAddressable?: boolean;
	sheetName?: string;
};

const PopUpSlide = ({
	visible,
	heading,
	children,
	isCategoriesModal,
	isFiltersModal,
	isSelectorModal,
	ContainerClassName,
	onClose,
	scrollContainerId = '',
	allowTouchEvents,
	fullHeight = true,
	zIndex,
}: Props) => {
	const [startY, setStartY] = useState(0);
	const [startTime, setStartTime] = useState<number | null>(null);
	const popupRef = useRef() as MutableRefObject<HTMLDivElement>;
	const topDraggerRef = useRef() as MutableRefObject<HTMLDivElement>;

	useBodyScrollLock(visible);

	const clickEvent = () => {
		if (onClose) {
			onClose();
		}
	};

	const onTouchStart = (event: React.TouchEvent) => {
		const { target } = event;
		const scrollContainer = document.getElementById(scrollContainerId);
		if (target !== topDraggerRef.current && scrollContainer?.scrollTop)
			return;

		setStartY(event.touches[0].clientY);
		setStartTime(Date.now());
	};

	const onTouchMove = (event: React.TouchEvent) => {
		const { target } = event;
		const scrollContainer = document.getElementById(scrollContainerId);
		if (target !== topDraggerRef.current && scrollContainer?.scrollTop) {
			popupRef.current.style.transform = '';
			return;
		}

		const deltaY = event.touches[0].clientY - startY;
		if (deltaY > 0) {
			popupRef.current.style.transform = `translateY(${deltaY}px)`;
		}
	};

	const onTouchEnd = (event: React.TouchEvent) => {
		const { target } = event;
		const scrollContainer = document.getElementById(scrollContainerId);
		if (target !== topDraggerRef.current && scrollContainer?.scrollTop) {
			popupRef.current.style.transform = '';
			return;
		}

		const { clientY } = event.changedTouches[0];
		const deltaY = clientY - startY;
		const velocity = deltaY / (Date.now() - startTime!);
		if (popupRef.current.clientHeight / 5 < deltaY || velocity > 3) {
			popupRef.current.style.transform = '';
			clickEvent();
		} else {
			popupRef.current.style.transform = '';
		}
		setStartY(0);
	};

	return (
		<div
			className={cx(
				corePopupSlide({ fullHeight, allowTouchEvents }),
				'core-popup-container',
				ContainerClassName,
				visible && 'core-popup-show',
			)}
			style={{ zIndex: zIndex ?? 999 }}
		>
			<div
				className={cx(corePopupBackground, 'core-popup-background')}
				onClick={clickEvent}
			/>

			<Conditional
				if={!(isCategoriesModal || isFiltersModal || isSelectorModal)}
			>
				<div
					className={cx(
						corePoppupSlideHeader,
						'core-popup-slide-header',
					)}
				>
					<span className='core-popup-slide-heading'>{heading}</span>
					<span onClick={clickEvent}>
						<CancelSvg />
					</span>
				</div>
			</Conditional>
			<div
				ref={popupRef}
				className={cx(
					'core-popup-slide-body',
					isCategoriesModal && 'modal-categories-selection',
					isFiltersModal && 'modal-filters-selection',
				)}
				onTouchStart={allowTouchEvents ? onTouchStart : () => {}}
				onTouchMove={allowTouchEvents ? onTouchMove : () => {}}
				onTouchEnd={allowTouchEvents ? onTouchEnd : () => {}}
			>
				<div
					className={cx(topDragger, 'top-dragger')}
					ref={topDraggerRef}
				/>
				<Conditional
					if={isCategoriesModal || isFiltersModal || isSelectorModal}
				>
					<div className={topNotch} />
				</Conditional>
				{children}
			</div>
		</div>
	);
};

export default PopUpSlide;
