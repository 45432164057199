import React from 'react';

function MobileTicket() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
		>
			<path
				fill='#60C'
				stroke='#60C'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M14.166 1.667H5.833c-.92 0-1.667.746-1.667 1.666v13.334c0 .92.747 1.666 1.667 1.666h8.334c.92 0 1.666-.746 1.666-1.666V3.333c0-.92-.746-1.666-1.667-1.666z'
			></path>
			<path
				stroke='#F8F8F8'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M10 16.875h.008'
			></path>
			<path
				fill='#FFF8EF'
				d='M9.776 5.447a.25.25 0 01.448 0l.903 1.806a.25.25 0 00.186.135l1.997.301a.25.25 0 01.138.426L12.01 9.532a.25.25 0 00-.071.219l.33 1.992a.25.25 0 01-.361.263l-1.793-.93a.25.25 0 00-.23 0l-1.793.93a.25.25 0 01-.361-.263l.33-1.992a.25.25 0 00-.07-.22L6.55 8.116a.25.25 0 01.139-.426l1.997-.3a.25.25 0 00.186-.136l.903-1.806z'
			></path>
		</svg>
	);
}

export default MobileTicket;
