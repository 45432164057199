import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Conditional from 'Components/common/conditional';
import {
	ProductDescriptor,
	TComboDaySchedule,
	TOperatingHoursTableInfo,
	TProductDescriptor,
} from 'Components/common/descriptors/interface';
import {
	Descriptor,
	DescriptorActionTitle,
	DescriptorsWrapper,
	DescriptorTitle,
	IconWrapper,
	InfoWrapper,
	LongDescriptorsWrapper,
	ModalWrapper,
} from 'Components/common/descriptors/style';
import LP from 'Components/common/localizedTags/localizedParagraph';
import Modal from 'Components/common/modal';
import RenderOneOf from 'Components/common/renderOneOf';
import type {
	TOperatingDaySchedule,
	TPoiData,
} from 'Components/desktop/operatingHoursTable/type';
import PopUpSlide from 'Components/mobile/popUpSlide';

import { ChevronRightSvg } from 'Assets/svg/collections';
import AudioGuide from 'Assets/svg/descriptors/audioGuide';
import Duration from 'Assets/svg/descriptors/duration';
import ExtendedValidity from 'Assets/svg/descriptors/extendedValidity';
import FreeCancellationSvg from 'Assets/svg/descriptors/freeCancellation';
import GuidedTour from 'Assets/svg/descriptors/guidedTour';
import HotelPickup from 'Assets/svg/descriptors/hotelPickup';
import InstantConfirmation from 'Assets/svg/descriptors/instantConfirmation';
import MealsIncluded from 'Assets/svg/descriptors/mealsIncluded';
import MobileTicket from 'Assets/svg/descriptors/mobileTicket';
import {
	OperatingHoursClosed,
	OperatingHoursOpen,
} from 'Assets/svg/descriptors/operatingHours';
import Transfers from 'Assets/svg/descriptors/transfers';

import { trackEvent } from 'Utils/analytics';
import { isTourGroupOpenDated } from 'Utils/bookingFlowUtils';
import {
	formatDurationToHoursMinutes,
	getDurationInDays,
	getDurationInHours,
} from 'Utils/dateUtils';
import { getOperatingHoursInfo } from 'Utils/productUtils';
import { getDeviceType } from 'Utils/stateUtils';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { DEVICE_TYPE, SKIP_FLEXI_DESCRIPTOR_LIST } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

const SingleOhTableMweb = dynamic(
	() => import('Components/common/descriptors/singleOhTableMweb'),
	{ ssr: false },
);

const SingleOhTableDweb = dynamic(
	() => import('Components/common/descriptors/singleOhTableDweb'),
	{ ssr: false },
);

const ComboOhTableMweb = dynamic(
	() => import('Components/common/descriptors/comboOhTableMweb'),
	{ ssr: false },
);

const ComboOhTableDweb = dynamic(
	() => import('Components/common/descriptors/comboOhTableDweb'),
	{ ssr: false },
);

const DESCRIPTORS_RANKING = [
	ProductDescriptor.DURATION,
	ProductDescriptor.OPERATING_HOURS,
	ProductDescriptor.FLEXIBLE_DURATION,
	ProductDescriptor.FREE_CANCELLATION,
	ProductDescriptor.EXTENDED_VALIDITY,
	ProductDescriptor.AUDIO_GUIDE,
	ProductDescriptor.GUIDED_TOUR,
	ProductDescriptor.TRANSFERS,
	ProductDescriptor.HOTEL_PICKUP,
	ProductDescriptor.MEALS_INCLUDED,
	ProductDescriptor.INSTANT_CONFIRMATION,
	ProductDescriptor.MOBILE_TICKET,
];

type OperatingHoursInfo = {
	isOpen: boolean;
	poiName: string;
	label: string;
	operatingDaySchedules: TOperatingDaySchedule[];
	subtext?: string;
	nextAvailableStartDate?: string;
};

type TLongDescriptorsProps = {
	product: Record<string, any>;
	lang: string;
	poiInfo: Record<string, any>;
	excludedDescriptors?: ProductDescriptor[];
};

const LongDescriptors = ({
	product,
	lang,
	poiInfo,
	excludedDescriptors,
}: TLongDescriptorsProps) => {
	const {
		descriptors,
		cancellationPolicyV2: cancellationPolicy,
		minDuration,
		maxDuration,
		ticketValidity,
		city,
		id,
	} = product;
	const { timeZone: experienceTimezone } = city;
	const { ticketValidityUntilDate, ticketValidityUntilDaysFromPurchase } =
		ticketValidity;

	const [showOhTable, setShowOhTable] = useState(false);
	const [operatingHoursTableInfo, setOperatingHoursTableInfo] =
		useState<TOperatingHoursTableInfo | null>(null);
	const [experienceDescriptors, setExperienceDescriptors] = useState([]);
	const isMobile = useSelector(
		state => getDeviceType(state) === DEVICE_TYPE.MOBILE,
	);

	const showOperatingHours = () => {
		setShowOhTable(true);
		trackEvent({
			eventName: 'Experience Page Section Clicked',
			[ANALYTICS_PROPERTIES.SECTION]: 'View all timings',
			[ANALYTICS_PROPERTIES.ACTION]: 'Open',
		});
	};

	const handleClose = () => {
		setShowOhTable(false);
	};

	const cancellationText =
		cancellationPolicy?.cancellableUpTo >= 4320
			? strings.FREE_CANCELLATION_BANNER.CANCELLATION_BEFORE_X_DAYS
			: strings.FREE_CANCELLATION_BANNER.CANCELLATION_BEFORE_X_HOURS;

	const LONG_DESCRIPTORS_MAP: Record<string, any> = {
		FREE_CANCELLATION: {
			icon: <FreeCancellationSvg />,
			background: colors.FADED_GREEN,
			label: strings.PPD_FREE_CANCEL,
			subtext: strings.formatString(
				cancellationText,
				cancellationPolicy?.cancellableUpTo >= 4320
					? getDurationInDays(cancellationPolicy.cancellableUpTo)
					: getDurationInHours(cancellationPolicy.cancellableUpTo),
			),
		},
		EXTENDED_VALIDITY: {
			icon: <ExtendedValidity />,
			background: colors.PALE_BLUE,
			label: strings.PPD_VALIDITY,
			subtext: strings.formatString(
				strings.PPD_EXTENDED_VALIDITY,
				ticketValidityUntilDate
					? dayjs(ticketValidityUntilDate).format('D MMM, YYYY')
					: dayjs()
							.tz(experienceTimezone)
							.add(ticketValidityUntilDaysFromPurchase, 'days')
							.format('D MMM, YYYY'),
			),
		},
		FLEXIBLE_DURATION: {
			icon: <Duration />,
			background: colors.FADED_PALE,
			label: strings.PPD_FLEXIBLE_DURATION,
			subtext: strings.PPD_FLEXIBLE_DURATION_SUBTEXT,
		},
		DURATION: {
			icon: <Duration />,
			background: colors.FADED_PALE,
			label: strings.DURATION,
			boldSubtext: true,
			subtext:
				maxDuration && minDuration && maxDuration !== minDuration
					? `${formatDurationToHoursMinutes(
							minDuration,
					  )} - ${formatDurationToHoursMinutes(maxDuration)}`
					: maxDuration
					? formatDurationToHoursMinutes(maxDuration)
					: minDuration
					? formatDurationToHoursMinutes(minDuration)
					: '',
		},
		OPERATING_HOURS: {
			icon: <OperatingHoursClosed />,
			background: colors.GREY_DS.G8,
			label: strings.CLOSED,
			actionLabel: strings.PPD_VIEW_TIMINGS,
			action: showOperatingHours,
		},
		AUDIO_GUIDE: {
			icon: <AudioGuide />,
			background: colors.PALE_CYAN,
			label: strings.PPD_AUDIO_GUIDE,
			subtext: strings.PPD_AUDIO_GUIDE_SUBTEXT,
		},
		MEALS_INCLUDED: {
			icon: <MealsIncluded />,
			background: colors.FADED_PALE,
			label: strings.PPD_MEALS_INCLUDED,
			subtext: strings.PPD_MEALS_INCLUDED_SUBTEXT,
		},
		INSTANT_CONFIRMATION: {
			icon: <InstantConfirmation />,
			background: colors.PALE_BLUE,
			label: strings.PPD_INSTANT_CONFIRMATION,
		},
		MOBILE_TICKET: {
			icon: <MobileTicket />,
			background: colors.GREY.FLOATING,
			label: strings.PPD_MOBILE_TICKET,
		},
		TRANSFERS: {
			icon: <Transfers />,
			background: colors.FADED_PALE,
			label: strings.PPD_RETURN_TRANSFERS,
		},
		HOTEL_PICKUP: {
			icon: <HotelPickup />,
			background: colors.FADED_PALE,
			label: strings.PPD_HOTEL_PICKUP,
		},
		GUIDED_TOUR: {
			icon: <GuidedTour />,
			background: colors.PALE_CYAN,
			label: strings.PPD_GUIDED_TOUR,
		},
	};

	const getDescriptorsData = useCallback(() => {
		const availableDescriptors = descriptors
			.filter(
				(descriptor: TProductDescriptor) =>
					DESCRIPTORS_RANKING.includes(descriptor.code) &&
					!excludedDescriptors?.includes(descriptor.code),
			)
			.map(({ code }: TProductDescriptor) => code);

		if (
			isTourGroupOpenDated(product) &&
			availableDescriptors.includes(ProductDescriptor.EXTENDED_VALIDITY)
		) {
			const extendedValidityIndex = availableDescriptors.indexOf(
				ProductDescriptor.EXTENDED_VALIDITY,
			);
			availableDescriptors.splice(extendedValidityIndex, 1);
		}

		if (!minDuration || !maxDuration) {
			if (!SKIP_FLEXI_DESCRIPTOR_LIST.includes(id)) {
				availableDescriptors.push(ProductDescriptor.FLEXIBLE_DURATION);
			}
		} else {
			availableDescriptors.push(ProductDescriptor.DURATION);
		}

		const hasOperatingHours =
			poiInfo?.length &&
			poiInfo.some(
				(poiData: TPoiData) => poiData.operatingSchedules?.length,
			);

		if (hasOperatingHours) {
			availableDescriptors.push(ProductDescriptor.OPERATING_HOURS);

			const operatingHoursInfo: OperatingHoursInfo[] = poiInfo.map(
				({ name, operatingSchedules }: TPoiData) =>
					getOperatingHoursInfo(
						name,
						operatingSchedules,
						experienceTimezone,
					),
			);

			if (operatingHoursInfo.length > 1) {
				const closedOperatingHours = operatingHoursInfo
					.filter(({ isOpen }: { isOpen: boolean }) => !isOpen)
					.sort((a: any, b: any) => {
						if (
							a.nextAvailableStartDate &&
							b.nextAvailableStartDate
						) {
							return (
								Date.parse(b.nextAvailableStartDate) -
								Date.parse(a.nextAvailableStartDate)
							);
						} else if (a.nextAvailableStartDate) {
							return -1;
						} else if (b.nextAvailableStartDate) {
							return 1;
						} else if (
							a.label !== strings.PPD_OPENS_TOMORROW &&
							b.label === strings.PPD_OPENS_TOMORROW
						) {
							return -1;
						} else if (
							a.label === strings.PPD_OPENS_TOMORROW &&
							b.label !== strings.PPD_OPENS_TOMORROW
						) {
							return 1;
						} else {
							return 0;
						}
					});

				if (closedOperatingHours.length) {
					const closedOperatingHoursInfo = closedOperatingHours[0];
					let { label: closedLabel } = closedOperatingHoursInfo;

					// Check the other POI if tomorrow is closed.
					const currentPoiDay = dayjs().tz(experienceTimezone);
					const tomorrowDayOfWeek = currentPoiDay
						.add(1, 'day')
						.locale('en')
						.format('dddd')
						.toUpperCase();

					const hasClosedScheduleTomorrow = operatingHoursInfo.some(
						({ operatingDaySchedules }) =>
							operatingDaySchedules?.find(
								({ closed, dayOfWeek }) =>
									closed && dayOfWeek === tomorrowDayOfWeek,
							),
					);

					if (hasClosedScheduleTomorrow) {
						// Find the next available day schedule within the current week.
						let daysUntilNextAvailableDay = 2; // Start 2 days from now (day after tomorrow).

						while (daysUntilNextAvailableDay <= 7) {
							const currentDayOfWeek = currentPoiDay.add(
								daysUntilNextAvailableDay,
								'day',
							);

							const hasClosedNextDaySchedule =
								operatingHoursInfo.some(
									({ operatingDaySchedules }) =>
										operatingDaySchedules?.find(
											({ closed, dayOfWeek }) =>
												closed &&
												dayOfWeek ===
													currentDayOfWeek
														.locale('en')
														.format('dddd')
														.toUpperCase(),
										),
								);

							if (!hasClosedNextDaySchedule) {
								closedLabel = strings.formatString(
									strings.PPD_OPENS_ON,
									currentDayOfWeek.format('dddd'),
								);

								break;
							}

							daysUntilNextAvailableDay += 1;
						}
					}

					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.label = closedLabel;
				} else {
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.icon = (
						<OperatingHoursOpen />
					);
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.background =
						colors.FADED_GREEN;
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.labelColor =
						colors.GREEN_3;
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.label =
						operatingHoursInfo[0].label;
				}

				const hasUnavailableSchedule = operatingHoursInfo.some(
					({ label }) => label === strings.CLOSED,
				);

				if (!hasUnavailableSchedule) {
					setOperatingHoursTableInfo({
						isCombo: true,
						daySchedules: operatingHoursInfo.map(
							({ poiName, operatingDaySchedules }: any) => ({
								poiName,
								operatingDaySchedules,
							}),
						),
					});
				}
			} else {
				const { label, subtext, isOpen, operatingDaySchedules } =
					operatingHoursInfo[0];

				if (operatingDaySchedules)
					setOperatingHoursTableInfo({
						isCombo: false,
						daySchedules: operatingDaySchedules,
					});

				if (isOpen) {
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.icon = (
						<OperatingHoursOpen />
					);
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.background =
						colors.FADED_GREEN;
					LONG_DESCRIPTORS_MAP.OPERATING_HOURS.labelColor =
						colors.GREEN_3;
				}

				LONG_DESCRIPTORS_MAP.OPERATING_HOURS.label = label;
				LONG_DESCRIPTORS_MAP.OPERATING_HOURS.subtext = subtext;
			}
		}

		const mappedDescriptorsList: any = DESCRIPTORS_RANKING.filter(
			(code: string) => availableDescriptors.includes(code),
		).map((code: string) => LONG_DESCRIPTORS_MAP[code]);

		return mappedDescriptorsList;
	}, [lang]);

	useEffect(() => {
		const mappedDescriptorsList = getDescriptorsData();
		setExperienceDescriptors(mappedDescriptorsList);
	}, [getDescriptorsData]);

	return (
		<LongDescriptorsWrapper>
			<DescriptorsWrapper>
				{experienceDescriptors.map((descriptor, index) => {
					const {
						boldSubtext,
						subtext,
						reversedColumn,
						background,
						icon,
						label,
						action,
						actionLabel,
						labelColor,
					} = descriptor;
					return (
						<Descriptor
							key={index}
							className={classNames({
								'is-combo':
									!!operatingHoursTableInfo?.isCombo &&
									!!action,
							})}
						>
							<IconWrapper
								$backgroundColor={background}
								className='descriptor-icon'
							>
								{icon}
							</IconWrapper>

							<InfoWrapper
								className={classNames({
									'descriptor-info': true,
									'bold-subtext': boldSubtext,
									'no-subtext': !subtext,
									'reversed-column': reversedColumn,
								})}
							>
								<RenderOneOf
									positionalConditions={[
										!action || !operatingHoursTableInfo,
										action,
									]}
								>
									<DescriptorTitle className='descriptor-label'>
										{label}
									</DescriptorTitle>

									<DescriptorActionTitle
										$labelColor={labelColor}
										aria-label={actionLabel}
										onClick={action}
									>
										<LP className='descriptor-label'>
											{label}
										</LP>
										<ChevronRightSvg
											className='descriptor-icon'
											stroke={labelColor}
										/>
									</DescriptorActionTitle>
								</RenderOneOf>

								<Conditional if={subtext}>
									<div className='descriptor-text'>
										{subtext}
									</div>
								</Conditional>
							</InfoWrapper>
						</Descriptor>
					);
				})}
			</DescriptorsWrapper>

			{!!operatingHoursTableInfo && (
				<RenderOneOf positionalConditions={[isMobile, !isMobile]}>
					<PopUpSlide
						visible={showOhTable}
						allowTouchEvents
						isSelectorModal={true}
						fullHeight={false}
						onClose={handleClose}
						ContainerClassName={classNames({
							'combo-popup-content':
								!!operatingHoursTableInfo?.isCombo,
						})}
					>
						<RenderOneOf
							positionalConditions={[
								!operatingHoursTableInfo.isCombo,
								operatingHoursTableInfo.isCombo,
							]}
						>
							<SingleOhTableMweb
								poiName={poiInfo[0].name}
								closeCallback={handleClose}
								daySchedules={
									operatingHoursTableInfo.daySchedules as TOperatingDaySchedule[]
								}
								lang={lang}
								timezone={experienceTimezone}
							/>

							<ComboOhTableMweb
								daySchedules={
									operatingHoursTableInfo.daySchedules as TComboDaySchedule[]
								}
								closeCallback={handleClose}
								lang={lang}
								timezone={experienceTimezone}
							/>
						</RenderOneOf>
					</PopUpSlide>

					<ModalWrapper onClick={handleClose}>
						<Modal open={showOhTable}>
							<RenderOneOf
								positionalConditions={[
									!operatingHoursTableInfo.isCombo,
									operatingHoursTableInfo.isCombo,
								]}
							>
								<SingleOhTableDweb
									poiName={poiInfo[0].name}
									closeCallback={handleClose}
									daySchedules={
										operatingHoursTableInfo.daySchedules as TOperatingDaySchedule[]
									}
									lang={lang}
									timezone={experienceTimezone}
								/>

								<ComboOhTableDweb
									daySchedules={
										operatingHoursTableInfo.daySchedules as TComboDaySchedule[]
									}
									closeCallback={handleClose}
									lang={lang}
									timezone={experienceTimezone}
								/>
							</RenderOneOf>
						</Modal>
					</ModalWrapper>
				</RenderOneOf>
			)}
		</LongDescriptorsWrapper>
	);
};

export default React.memo(LongDescriptors);
